import React, {useState} from 'react'
import { OrganizationContainer } from './Organization.style'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ScrollToTop from '../../components/ScrollToTop'
import { imagesLink } from './constant'
import {imagesLink as commonImagesLink } from '../../constants/imageLink'

const Organization = () => {
    const [activeCommissionerTab, setActiveCommissionerActiveTab] = useState(1)
    const [activeDirectorTab, setActiveDirectorActiveTab] = useState(1)
    const [openSections, setOpenSections] = useState({});

    const handleCommissionerTab = (index) => {
        setActiveCommissionerActiveTab(index)
    }

    const handleDirectorTab = (index) => {
        setActiveDirectorActiveTab(index)
    }

    const toggleSection = (section) => {
        setOpenSections((prev) => ({
          ...prev,
          [section]: !prev[section],
        }));
      };

  return (
    <>
        <Header />
        <OrganizationContainer>
            <div className='hero-section'>
                <h1>Lebih Dekat dengan Pengurus dan Struktur TAP Insure</h1>
                <img src={imagesLink.heroImage}/>
            </div>
            <div className='content'>
                <div className='content-inner'>
                    <h1 className='section-title'>
                        Jajaran Pengurus
                    </h1>
                    <div className='section-subtitle'>
                        <h2>Dewan Komisaris</h2>
                    </div>
                    <div className='committee-container'>
                        <div className='committee-items'>
                            <div className='committee-items-inner'>
                                <div className={`committee-name ${activeCommissionerTab === 1 ? 'active' : ''}`}>
                                    <h2>Edy Setiadi</h2>
                                    <h5>Komisaris Utama dan Komisaris Independen</h5>
                                    <h5>PT Asuransi Untuk Semua</h5>
                                    <p>Menjabat sebagai Komisaris Utama dan Komisaris Independen PT Asuransi Untuk Semua sejak Agustus 2022. Beliau saat ini juga menjabat masih aktif sebagai Direktur Lembaga Pengembangan Perbankan Indonesia sejak April 2020. </p>
                                    <p>Sebelumnya menjabat sebagai Komisaris Independen Bank Muamalat untuk periode tahun 2018 sampai dengan 2022. Beliau berperan penting dalam perkembangan usaha lembaga keuangan dan perbankan di Indonesia dimana selama periode tahun 1982 sampai dengan 2014 beliau memegang jabatan sebagai Deputi Komisioner IKNB 1 di Otoritas Jasa Keuangan dan jabatan-jabatan penting lainnya pada Bank Indonesia. Dengan pengalaman lebih dari 30 tahun sebagai regulator di bidang keuangan, penguasaan beliau pada kebutuhan perkembangan asuransi dan perbankan di Indonesia tidak diragukan lagi. </p>
                                    <p>Beliau mendapatkan gelar Sarjana Peternakan dari Universitas Padjajaran pada 1981, dan kemudian meraih gelar Master Ekonomi Pertanian dari Ohio State University di Amerika Serikat pada 1992.</p>
                                </div>
                                <div className={`committee-pict ${activeCommissionerTab === 1 ? 'active' : ''}`}>
                                    <img src={imagesLink.edySetiadi}/>
                                </div>
                            </div>
                            <div className='committee-items-inner'>
                                <div className={`committee-name ${activeCommissionerTab === 2 ? 'active' : ''}`}>
                                    <h2>Yasril Rasyid</h2>
                                    <h5>Komisaris Independen</h5>
                                    <h5>PT Asuransi Untuk Semua</h5>
                                    <p>Menjabat sebagai Komisaris Independen PT Asuransi Untuk Semua sejak Agustus 2022. Beliau saat ini juga menjabat sebagai Komisaris Independen di PT Asuransi Jiwa IFG sejak [x]; selain itu juga berperan dalam Tim Penguji Fit and Proper Test Bagi Pihak Utama di Otoritas Jasa Keuangan hingga saat ini.</p>
                                    <p>Kepemimpinan beliau di dunia perasuransian dan keuangan terbukti dari pengalaman beliau selama lebih dari 30 tahun di PT Tugu Pratama Indonesia dengan jabatan terakhir sebagai Presiden Direktur (1984-2015) dan Presiden Direktur PT Reasuransi Maipark Indonesia (2015-2018); selain itu sempat pernah menjadi Konsultan Keuangan untuk berbagai proyek keuangan seperti di Asian Development Bank, World Bank dan Kementerian Keuangan Republik Indonesia.</p>
                                    <p>Beliau mendapatkan gelar Sarjana Teknik Industri dari Universitas Institut Teknologi Bandung pada tahun 1984.</p>
                                </div>
                                <div className={`committee-pict ${activeCommissionerTab === 2 ? 'active' : ''}`}>
                                    <img src={imagesLink.yasrilRasyid}/>
                                </div>
                            </div>
                            <div className='committee-items-inner'>
                                <div className={`committee-name ${activeCommissionerTab === 3 ? 'active' : ''}`}>
                                    <h2>Michael Alvin Saputra</h2>
                                    <h5>Komisaris Independen</h5>
                                    <h5>PT Asuransi Untuk Semua</h5>
                                    <p>Menjabat sebagai Komisaris PT Asuransi Untuk Semua sejak Agustus 2022. Beliau berpengalaman sebagai engineering technology subject matter expert pada bidang inovasi teknologi selama lebih dari 15 tahun. Saat ini juga menjabat sebagai Direktur Operasional di perusahaan jasa penunjang pengembangan teknologi asuransi (Insurtech) terdepan di Indonesia, PT PasarPolis Indonesia sejak April 2020. </p>
                                    <p>Sebelumnya beliau menjabat sebagai Head of Operations di PT Xiaomi Communications Indonesia (2019-2020) dan juga sebagai Direktur Operasional di PT Mobindo Teknologi Indonesia (HipCar) (2017-2019). Beliau mendapatkan gelar Bachelor of Electrical Engineering dari Purdue University pada 2006, dan kemudian meraih gelar Master of Electrical Engineering dari University of Texas at Dallas di Amerika Serikat pada 2008.</p>
                                </div>
                                <div className={`committee-pict ${activeCommissionerTab === 3 ? 'active' : ''}`}>
                                    <img src={imagesLink.michaelAlvin}/>
                                </div>
                            </div>
                        </div>
                        <div className='committee-btn-container'>
                            <div className={`committee-btn ${activeCommissionerTab === 1 ? 'active' : ''}`} onClick={() => handleCommissionerTab(1)}>
                                <img src={imagesLink.edySetiadi}/>
                                <div>
                                    <h3>Edy Setiadi</h3>
                                    <p>Komisaris Utama</p>
                                </div>
                            </div>
                            <div className={`committee-btn ${activeCommissionerTab === 2 ? 'active' : ''}`} onClick={() => handleCommissionerTab(2)}>
                                <img src={imagesLink.yasrilRasyid}/>
                                <div>
                                    <h3>Yasril Rasyid</h3>
                                    <p>Komisaris Independen</p>
                                </div>
                            </div>
                            <div className={`committee-btn ${activeCommissionerTab === 3 ? 'active' : ''}`} onClick={() => handleCommissionerTab(3)}>
                                <img src={imagesLink.michaelAlvin}/>
                                <div>
                                    <h3>Michael Alvin Saputra</h3>
                                    <p>Komisaris Independen</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='section-subtitle'>
                        <h2>Direksi</h2>
                    </div>
                    <div className='committee-container'>
                        <div className='committee-items'>
                            <div className='committee-items-inner'>
                                <div className={`committee-name ${activeDirectorTab === 1 ? 'active' : ''}`}>
                                    <h2>Cleosent Randing</h2>
                                    <h5>Direktur Operasional dan Marketing Direktur Utama PLT</h5>
                                    <h5>PT Asuransi Untuk Semua</h5>
                                    <p>Menjabat sebagai Direktur Operasional dan Marketing, dan juga Plt Direktur Utama PT Asuransi Untuk Semua sejak 2023. Sebelumnya beliau merupakan Pendiri & CEO dari PT PasarPolis Indonesia, perusahaan jasa penunjang pengembangan teknologi asuransi(Insurtech) selama periode 2015 sampai dengan 2022.</p>
                                    <p>Sebelum berkecimpung di dunia asuransi, beliau aktif berwirausaha sejak 2012, ketika beliau mendirikan perusahaan digital performance bernama iProspect Valuklik yang sempat meraih penghargaan; yang kemudian diakuisisi oleh Dentsu Aegis Network asal Jepang pada 2017. </p>
                                    <p>Beliau memiliki passion yang tinggi dalam merevolusi dan menyederhanakan proses berasuransi di Indonesia serta mendigitalisasi kegiatan distribusi asuransi di Indonesia hingga memperoleh penghargaan Ernst & Young's Entrepreneur of The Year for Technology & Digital di 2018. Kiprahnya di dunia bisnis start-up berhasil memimpin PasarPolis menjadi perusahaan insurtech anak bangsa pertama yang meraih pendanaan awal Seri A oleh tiga unicorn Indonesia - Gojek, Tokopedia dan Traveloka; dan setelahnya PasarPolis berhasil menerima pendanaan Seri B terbesar di Asia Tenggara untuk kategori industri insurtech pada September 2020. Selama memimpin PasarPolis, PasarPolis menyumbangkan kontribusi yang tinggi terhadap penetrasi asuransi serta kegiatan literasi asuransi di Indonesia.</p>
                                    <p>Beliau meraih gelar Bachelor of Business dan lulus dengan (Honours) di tahun 2010 dari Simon Fraser University di Vancouver, Kanada Marketing & International Business, dengan jurusan tambahan di bidang Ekonomi.</p>
                                </div>
                                <div className={`committee-pict ${activeDirectorTab === 1 ? 'active' : ''}`}>
                                    <img src={imagesLink.cleosentRanding}/>
                                </div>
                            </div>
                            <div className='committee-items-inner'>
                                <div className={`committee-name ${activeDirectorTab === 2 ? 'active' : ''}`}>
                                    <h2>Suherman Darmawan</h2>
                                    <h5>Direktur Teknik</h5>
                                    <h5>PT Asuransi Untuk Semua</h5>
                                    <p>Menjabat sebagai Direktur Teknik PT Asuransi Untuk Semua sejak 2023.
                                    Beliau sudah memberikan kontribusi sebagai tenaga ahli dan teknis asuransi di Indonesia selama hampir 30 tahun.</p>
                                    <p>Sebelum bergabung dengan PT Asuransi Untuk Semua, beliau menduduki posisi Direktur Teknik di PT MNC Asuransi Indonesia (Unit Bisnis PT MNC Kapital Indonesia Tbk) (2015-2022) dan pernah menduduki berbagai posisi pimpinan di PT Asuransi Raksa Pratikara (1995 - 2015) dengan jabatan terakhir sebagai Direktur.</p>
                                    <p>Beliau meraih gelar Sarjana Teknik dan Manajemen Industri pada tahun 1995 dari Universitas Trisakti Jakarta dan merupakan pemegang gelar profesi AAIK dari AAMAI dan ACII dari Chartered Insurance Institute UK</p>
                                </div>
                                <div className={`committee-pict ${activeDirectorTab === 2 ? 'active' : ''}`}>
                                    <img src={imagesLink.suhermanDarmawan}/>
                                </div>
                            </div>
                            <div className='committee-items-inner'>
                                <div className={`committee-name ${activeDirectorTab === 3 ? 'active' : ''}`}>
                                    <h2>Makki Ibrahim Kusuma</h2>
                                    <h5>Direktur Kepatuhan</h5>
                                    <h5>PT Asuransi Untuk Semua</h5>
                                    <p>Menjabat sebagai Direktur Kepatuhan PT Asuransi Untuk Semua sejak 2023. Memiliki pengalaman di dunia asuransi dan hukum korporasi selama lebih dari 20 tahun.</p>
                                    <p>Sebelum bergabung dengan PT Asuransi Untuk Semua, beliau merupakan General Counsel Legal Operations pada Air Asia SEA Sdn Bhd. yang berbasis di Kuala Lumpur dan General Counsel pada Igloo yang berbasis di Singapore, dimana beliau membawahi fungsi hukum dan kepatuhan pada Malaysia, Indonesia, Vietnam, Philippines dan China.</p>
                                    <p>Pada periode tahun 2011 sampai dengan 2018 beliau menjabat sebagai Direktur Legal dan Operations pada PT Avrist Assurance serta memimpin proses transformasi perusahaan hingga dianugerahi penghargaan sebagai Insurance Top Leader oleh Warta Ekonomi pada tahun 2018.
                                    Beliau berlatar belakang sebagai Corporate Lawyer yang telah berlisensi sebagai Advokat Indonesia sejak tahun 2007 dan aktif berperan sebagai konsultan hukum pada periode 2004 sampai dengan 2011 pada firma hukum ternama di Indonesia dengan fokus menangani urusan investment, mergers and acquisition..Beliau meraih gelar Sarjana Hukum dari Universitas Tarumanagara di tahun 2004.</p>
                                </div>
                                <div className={`committee-pict ${activeDirectorTab === 3 ? 'active' : ''}`}>
                                    <img src={imagesLink.makkiIbrahim}/>
                                </div>
                            </div>
                        </div>
                        <div className='committee-btn-container'>
                            <div className={`committee-btn ${activeDirectorTab === 1 ? 'active' : ''}`} onClick={() => handleDirectorTab(1)}>
                                <img src={imagesLink.cleosentRanding}/>
                                <div>
                                    <h3>Cleosent Randing</h3>
                                    <p>Direktur Operasional dan Marketing Direktur Utama PLT</p>
                                </div>
                            </div>
                            <div className={`committee-btn ${activeDirectorTab === 2 ? 'active' : ''}`} onClick={() => handleDirectorTab(2)}>
                                <img src={imagesLink.suhermanDarmawan}/>
                                <div>
                                    <h3>Suherman Darmawan</h3>
                                    <p>Direktur Teknik</p>
                                </div>
                            </div>
                            <div className={`committee-btn ${activeDirectorTab === 3 ? 'active' : ''}`} onClick={() => handleDirectorTab(3)}>
                                <img src={imagesLink.makkiIbrahim}/>
                                <div>
                                    <h3>Makki Ibrahim Saputra</h3>
                                    <p>Direktur Kepatuhan</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h1 className='section-title'>Struktur Organisasi</h1>
                    <div className='organization-structure'>
                        <img src={imagesLink.organizationStructure}/>
                    </div>
                    <h1 className='section-title'>
                        Komite PT Asuransi Untuk Semua
                    </h1>
                    <div className='committee-graph'>
                        <h3 onClick={() => toggleSection('section1')}>
                            Komite di bawah Dewan Komisaris
                            <div className={`arrow-aboutus ${openSections['section1'] ? 'open' : ''}`}>
                                <img src={commonImagesLink.arrowDown} alt="Arrow Down" />
                            </div>
                        </h3>
                        <div className={`committee-graph-inner ${openSections['section1'] ? 'open' : ''}`}>
                            <img src={imagesLink.commissionerGraph}/>
                        </div>
                    </div>
                    <div className='committee-graph'>
                        <h3 onClick={() => toggleSection('section2')}>
                            Komite di bawah Direksi
                            <div className={`arrow-aboutus ${openSections['section2'] ? 'open' : ''}`}>
                                <img src={commonImagesLink.arrowDown} alt="Arrow Down" />
                            </div>
                        </h3>
                        <div className={`committee-graph-inner ${openSections['section2'] ? 'open' : ''}`}>
                            <img src={imagesLink.directionGraph}/>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTop />
            <Footer />
        </OrganizationContainer>
    </>
  )
}

export default Organization
