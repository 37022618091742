import React from 'react'
import { VisiMisiContainer } from './VisiMisi.style'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { imagesLink } from './constant'
import ScrollToTop from '../../components/ScrollToTop'

const VisiMisi = () => {
  return (
    <>
      <Header />
      <VisiMisiContainer>
          <div className='hero-section'>
              <h1 className='hero-title'>Menghubungkan melalui Visi, Meraih dengan Misi, Dipandu oleh Nilai-Nilai</h1>
              <img src={imagesLink.heroImage}/>
          </div>
          <div className='content'>
            <div className='content-inner'>
              <img src={imagesLink.visiMisi}/>
              <div className='visi-misi'>
                <div className='visi-misi-title'>
                  <h1>Nilai Inti</h1>
                  <h1>Tap Insure</h1>
                </div>
                <div className='visi-misi-container'>
                  <div className='visi-misi-items'>
                      <h6>01</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Jangan Pernah Menyerah
                        </h3>
                        <p>Musuh terbesar kita bukanlah kegagalan. Ini adalah godaan untuk menjadi biasa.</p>
                      </div>
                  </div>
                  <div className='visi-misi-items'>
                      <h6>02</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Selalu Hari Pertama
                        </h3>
                        <p>Setiap hari adalah hari pertama. Bersikaplah rendah hati. Selalu haus akan ilmu.</p>
                      </div>
                  </div>
                  <div className='visi-misi-items'>
                      <h6>03</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Berjiwa Wirausaha
                        </h3>
                        <p>Kami mendorong insan TAP Insure untuk bertindak seperti pemilik usaha: memotivasi diri sendiri, berkesadaran tinggi, dan tidak menunggu untuk diberitahu apa yang harus dilakukan.</p>
                      </div>
                  </div>
                  <div className='visi-misi-items'>
                      <h6>04</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Kita adalah Satu Tim
                        </h3>
                        <p>MTidak ada seorang pun yang bisa melakukan sesuatu sendirian. Ketika kita menunjukkan kinerja kuat secara konsisten, maka orang lain dapat mengandalkan kita. Kita saling bantu untuk menjadi yang terbaik.</p>
                      </div>
                  </div>
                  <div className='visi-misi-items'>
                      <h6>05</h6>
                      <div className='visi-misi-items-inner'>
                        <h3>
                          Merangkul Perubahan
                        </h3>
                        <p>Perubahan tidak bisa dihindari. Beradaptasi, berevolusi, dan tetap berpikiran terbuka.</p>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollToTop />
          < Footer />
      </VisiMisiContainer>
    </>
  )
}

export default VisiMisi
