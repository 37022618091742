import React, { useState, useEffect } from 'react';
import { FinanceReportContainer } from './FinanceReport.style';
import { useGetFinanceReports } from '../../lib/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import YearDropdown from '../../components/YearDropdown';
import ScrollToTop from '../../components/ScrollToTop';

const FinanceReport = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const { reportData, loading, error, getFinanceReport } = useGetFinanceReports({ year: selectedYear });

  useEffect(() => {
    getFinanceReport();
  }, [selectedYear, getFinanceReport]);

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  return (
    <>
      <Header />
      <FinanceReportContainer>
        <div className="content">
          <h1>Laporan Keuangan</h1>
          <div className="content-inner">
            <YearDropdown onChange={handleYearChange} />
            <div className="finance-report-month-container">
              {loading ? (
                <p>Loading reports...</p>
              ) : error ? (
                <p>{error}</p>
              ) : reportData.length > 0 ? (
                reportData.map((report) => (
                  <div key={report.id} className="finance-report-items">
                    <p>{report.title}</p>
                    <a href={report.link} target="_blank" rel="noopener noreferrer">
                      <button className="btn-download">Selengkapnya</button>
                    </a>
                  </div>
                ))
              ) : (
                <p>No reports available</p>
              )}
            </div>
          </div>
        </div>
        <ScrollToTop />
        <Footer />
      </FinanceReportContainer>
    </>
  );
};

export default FinanceReport;
